import React from "react";
import Layout from "../components/layout";

import SEO from "../components/seo";
import "../styles/_about.scss";
import ogImage from "../images/OG_Image.png";

const AboutPage = () => (
  <Layout>
    <SEO
      title="About"
      ogImage={ogImage}
      keywords={[
        "Flowspring",
        "competitive intelligence",
        "asset managers",
        "about",
      ]}
      description={"Flowspring is the best way to understand your competitors, your investors, and the market for asset management products. We analyze hundreds of thousands of investment products to provide you with the most actionable, forward-looking intelligence possible. Whether you're launching a new product, re-evaluating your existing  product line-up, shifting your marketing spend, or optimizing distribution tactics, Flowspring's analysis will clarify the best path forward."}
    />
    <div id="aboutContent">
      <h2>
        Flowspring is an ISS Market Intelligence business that builds Competitive Intelligence Software for Asset Managers
      </h2>
      <div className=" sectionText">
        Flowspring&apos;s analytics are the best way to understand your competitors, your
        investors, and the market for asset management products. We analyze
        hundreds of thousands of investment products to provide you with the
        most actionable, forward-looking intelligence possible. Whether you&apos;re
        launching a new product, re-evaluating your existing product line-up,
        shifting your marketing spend, or optimizing distribution tactics,
        Flowspring&apos;s analysis will clarify the best path forward.
      </div>
      <h2>Who We Are</h2>
      <div className="sectionText">
        Flowspring is made up of committed quants with extensive experience
        analyzing the fundamental and quantitative nature of a broad range of
        investment products. We envision a world full of high quality, targeted,
        and affordable investment products, resulting in better financial
        outcomes for asset managers and investors alike. We&apos;re working hard to
        make that world a reality!
      </div>
      <h2>We Love Hearing From You!</h2>
      <div className="sectionText">
        Want to know more about Flowspring, have a chat about the asset
        management industry, or just say hello? We&apos;d love to hear from you.
        <a href="https://www.issgovernance.com/mi-universal-form/?aoi=Flowspring&country=United%20States">
          <div id="contactUsButton">Contact Us!</div>
        </a>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
